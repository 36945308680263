const html = require('choo/html');
const assets = require('../../common/assets');
const modal = require('./modal');

module.exports = function(state, emit) {
  return html`
    <main class="main">
      ${state.modal && modal(state, emit)}
      <section
        class="flex flex-col items-center justify-center h-full w-full p-6 md:p-8 overflow-hidden"
      >
        <h1 class="text-center text-3xl font-bold my-2">
          ${state.translate('errorPageHeader')}
        </h1>
        <svg class="text-primary my-12 h-48">
          <use xlink:href="${assets.get('error.svg')}#svg114" />
        </svg>
        <p
          class="max-w-md text-center text-grey-80 leading-normal dark:text-grey-40 ${state
            .user.loggedIn
            ? 'hidden'
            : ''}"
        >
          ${state.translate('trySendDescription')}
        </p>
      </section>
    </main>
  `;
};
