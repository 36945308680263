const html = require('choo/html');
const Component = require('choo/component');
const Account = require('./account');

class Header extends Component {
  constructor(name, state, emit) {
    super(name);
    this.state = state;
    this.emit = emit;
    this.account = state.cache(Account, 'account');
    this.company = null;

    this.fetchHeaders();
  }

  async fetchHeaders() {
    const result = await fetch('/');

    if (result.headers.get('x-company')) {
      this.fetchCompany(result.headers.get('x-company'));
    }
  }

  async fetchCompany(slug) {
    const result = await fetch(`https://sodostream.nl/api/companies/${slug}`, {mode: 'cors'});
    const payload = await result.json();

    this.company = payload.data;

    this.emit('setCompany', this.company);
  }

  update() {
    this.account.render();

    return true;
  }

  createElement() {
    if (!this.state.company) {
      return html`<header></header>`;
    }

    return html`
      <header
        class="main-header"
      >
        <style>
          :root {
            --color-primary: ${this.state.company.themeColor} !important;
            --color-primary-accent: ${this.state.company.themeColor} !important;
            --color-primary-text: ${this.state.company.themeTextColor} !important;
          }
        </style>

        <a href="/">
          <figure class="main-header__logo-figure">

            <img
              class="main-header__logo"
              src="https://sodostream.nl/assets/${this.state.company.logoUrl}"
              alt="${this.state.company.name} Logo"
            />
          </figure>
        </a>
      </header>
    `;
  }
}

module.exports = Header;
