const html = require('choo/html');
const Header = require('./header');
const Footer = require('./footer');

module.exports = function body(main) {
  return function(state, emit) {
    const advertisement = state.advertisement !== undefined ? html`
      <section class="advertisement">
        <figure class="advertisement__figure">
          <img
            class="advertisement__image"
            src="https://sodostream.nl/assets/${state.advertisement.foregroundImagePath}"
          />
        </figure>

        <article class="advertisement__content">
          <h2 class="text-3xl font-bold md:pb-2">
            ${state.advertisement.title}
          </h2>
          <p class="max-w-sm leading-loose mt-6 md:mt-2 md:pr-14">
            ${state.advertisement.description}
          </p>

          <div class="advertisement__button-container">
            <a
              class="btn rounded-lg flex items-center mt-4"
              href="/go?clickable_type=App\\Models\\Advertisement&clickable_id=${state.advertisement.id}"
              target="_blank"
            >
              ${state.advertisement.actionText}
            </a>
          </div>
        </article>
      </section>
    ` : html ``;

    const b = html`
      <body
        class="flex flex-col items-center font-sans md:bg-grey-10 dark:bg-black"
      >
        ${state.cache(Header, 'header').render()}

        ${main(state, emit)}

        ${advertisement}

        ${state.cache(Footer, 'footer').render()}
      </body>
    `;
    if (state.layout) {
      // server side only
      return state.layout(state, b);
    }
    return b;
  };
};
